<template>
  <div class="tags">
    <van-cell-group>
      <van-field
        placeholder="请输入账号"
        bind:change="onChange"
        v-model="account"
        label="账号"
        @blur="queryUser"
        :maxlength="11"
      />
      <van-field
        placeholder="请输入姓名"
        bind:change="onChange"
        v-model="name"
        label="姓名"
        readonly
      />
      <van-field
        placeholder="变更的客服"
        v-model="cusValue"
        label="客服名称"
        readonly
      />
      <van-field
        placeholder="申请理由"
        v-model="remark"
        label="申请理由"
        readonly
      />
    </van-cell-group>
    <div class="footer">
      <van-button
        type="default"
        size="normal "
        style="float: left; width: 50%"
        :square="true"
        @click="reject()"
        :disabled="rejDis"
        >驳回</van-button
      >
      <van-button
        type="info"
        size="normal "
        style="width: 50%"
        :square="true"
        @click="approve()"
        :disabled="appDis"
        >通过</van-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      account: "", //账号
      name: "", //姓名
      remark: "", //变更理由
      userId: "", //用户id
      cusValue: "", //客服姓名
      status: 1,
      logId: "",
      approveUser: "",
      rejDis:false,
      appDis:false
    };
  },
  methods: {
    reject() {
      this.status = 2;
      this.send();
    },
    approve() {
      this.status = 1;
      this.send();
    },
    send() {
      this.$axios
        .post(`${this.$base}/fission/lbEnterpriseWeChatUser/approve`, {
          status: this.status,
          userId: this.userId,
          id: this.logId,
          approve: this.approveUser,
        })
        .then((res) => {
          if (res.code === "200") {
            this.appDis=true
            this.rejDis=true
            this.$toast("操作成功");
          }
        });
    },
  },
  created() {
    this.account = this.$route.query.phone;
    this.userId = this.$route.query.userId;
    this.name = this.$route.query.name;
    this.cusValue = this.$route.query.cusName;
    this.remark = this.$route.query.remark;
    this.logId = this.$route.query.id;
    this.approveUser = this.$route.query.approveUser;
  },
};
</script>
<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
</style>
